<template>
  <div class="spare-out-record">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="出库记录" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="80px" :model="searchData" ref="searchDataRef">
       <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          style="margin-bottom: 0"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0 }"
          @change="searchFn"
        />
        <!-- 备件/工具名称 -->
        <ml-input
          prop="partNameLike"
          placeholder="请输入备件名称"
          style="margin-bottom: 0"
          label="备件名称:"
          v-model="searchData.partNameLike"
        />

        <!-- 日期 -->
        <ml-date-picker
          prop="date"
          startPlaceholder="开始日期"
          endPlaceholder="结束日期"
          label="出库时间:"
          type="datetimerange"
          v-model="searchData.date"
          :style="{ 'margin-bottom': 0, 'margin-right': '40px', 'width': '420px' }"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="50">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="所属项目"
          prop="sparePartVo.projectName"
          min-width="20%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="工单编号"
          prop="orderCode"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="备件/工具名称"
          prop="sparePartVo.partName"
          min-width="15%"
        />
        <el-table-column
          align="center"
          label="品牌/规格型号"
          prop="sparePartVo.model"
          min-width="15%"
        />
        <el-table-column
          align="center"
          label="生产日期"
          prop="sparePartVo.productDate"
          min-width="15%"
        >
          <template #default="scope">
            {{ formetData(scope.row.sparePartVo.productDate, 'year') }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="使用期限(月)"
          prop="sparePartVo.deadline"
          width="100"
        />
        <el-table-column
          align="center"
          label="存放地址"
          prop="sparePartVo.storageAddress"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="标准库存" prop="sparePartVo.planStock" width="100" />
        <el-table-column align="center" label="实际库存" prop="sparePartVo.realStock" width="100" />
        <el-table-column align="center" label="出库数量" prop="planOutStock" width="100" />
        <el-table-column align="center" label="单位" prop="sparePartVo.unit" min-width="10%" />
        <el-table-column
          align="center"
          label="出库原因"
          prop="outReason"
          width="100"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="出库时间" prop="createTime" width="100" />
        <el-table-column align="center" label="出库状态" prop="outStatus" width="100">
          <template #default="scope">
            <span v-if="scope.row.outStatus == 0">待审核</span>
            <span v-else-if="scope.row.outStatus == 1">待办结</span>
            <span v-else-if="scope.row.outStatus == 2">已办结</span>
            <span v-else-if="scope.row.outStatus == 3">审核退回</span>
            <span v-else-if="scope.row.outStatus == 4">办结退回</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" priop="partId" width="300px">
          <template #default="{ row }">
            <el-button
              size="mini"
              icon="el-icon-refresh"
              type="primary"
              @click="checkHandle(row)"
              v-if="
                (row.outStatus == 0 || row.outStatus == 1 || row.outStatus == 4) &&
                row.checkById &&
                row.checkById.indexOf(userId) > -1
              "
            >
              审核
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-edit"
              v-if="row.outStatus == 3 && row.checkById && row.checkById.indexOf(userId) > -1"
              type="primary"
              @click="editkHandle(row)"
            >
              修改
            </el-button>
            <el-button size="mini" icon="el-icon-view" type="primary" @click="clickView(row)">
              详情
            </el-button>
            <el-button size="mini" icon="el-icon-edit" type="danger" @click="clickDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除设备提示框 -->
    <ml-dialog
      ref="deleteSparePartsDialogRef"
      :content="deleteSparePartsData.content"
      @click-submit="submitDeleteSpareParts"
    />
    <!-- 审核 -->
    <ml-dialog
      width="600px"
      ref="spareRecordDialogRef"
      title="审核"
      @click-submit="submitSpareRecord"
    >
      <template #body>
        <ml-form
          style="width: 100%; padding: 0 20px"
          labelWidth="80px"
          :model="spareRecordData"
          ref="searchDataRef"
        >
          <!-- 审核 -->
          <el-form-item label="审核" prop="apprStatus">
            <el-radio-group v-model="spareRecordData.apprStatus">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="0">驳回</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 原因 -->
          <ml-input
            prop="content"
            placeholder="请输入描述"
            style="margin-bottom: 0"
            label="描述:"
            type="textarea"
            v-model="spareRecordData.content"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 修改 -->
    <ml-dialog
      width="600px"
      ref="sparePartsRecordDialogRef"
      :title="sparePartsRecordData.title"
      @click-submit="submitSparePartsRecord"
    >
      <template #body>
        <ml-form
          style="width: 100%; padding: 0 20px"
          labelWidth="100px"
          :model="sparePartsRecordData"
          ref="sparePartsFormRef"
          :rules="sparePartsFormRules"
        >
          <!-- 数量 -->
          <ml-input-number
            prop="number"
            placeholder="请输入数量"
            label="数量:"
            :min="0"
            v-model="sparePartsRecordData.number"
          />
          <!-- 原因 -->
          <ml-input
            prop="why"
            placeholder="请输入原因"
            style="margin-bottom: 0"
            label="原因:"
            v-model="sparePartsRecordData.why"
          />
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch, computed } from 'vue'
import { tabberHeight, searchParams, getTabberData, formetData, replacePerCent } from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'SpareOutRecord',
  setup() {
    const { commit, dispatch, getters } = useStore()
    const router = useRouter()
    const getMemoryPage = computed(() => getters.getMemoryPage)
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      partNameLike: '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      date: []
    })
    const userId = computed(() => getters.getUserId)
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getSpareOutRecordTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getSpareOutRecordTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getSpareOutRecordTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'])
      if (searchData.date.length > 0) {
        searchDataParams.createTimeStart = formetData(searchData.date[0])
        searchDataParams.createTimeEnd = formetData(searchData.date[1])
      }
      searchDataParams.partNameLike = replacePerCent(searchData.partNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetSpareOutRecords',
        params,
        searchDataParams
      )
      let list = data.map(d => {
        return {
          ...d,
          checkById: d.checkById ? d.checkById.split(',') : []
        }
      })
      tabberData.data = list || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getSpareOutRecordTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    /* 结束 数据列表 **********************************/
    
    /* 开始 搜索 **********************************/
    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      async newvalue => {
        if (newvalue) {
          await getProjectData(newvalue)
        }
      }
    )
    
    // 设备列表
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    /* 结束 搜索 **********************************/

    // 删除
    const deleteSparePartsDialogRef = ref()
    const deleteSparePartsData = reactive({
      content: '确定删除该出库记录吗？'
    })
    const clickDelete = row => {
      deleteSparePartsData.recordId = row.recordId
      deleteSparePartsDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteSpareParts = () => {
      const { recordId } = deleteSparePartsData
      dispatch('fetchspareOutRecord', recordId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getSpareOutRecordTabberData() : (tabberData.page = 1)
          deleteSparePartsDialogRef.value.showDialog = false
        }
      })
    }
    // 审核
    const spareRecordDialogRef = ref()
    const spareRecordData = reactive({
      operateType: 2,
      apprStatus: 1,
      content: '',
      businessId: '',
      nodeName: '',
      outStatus: 0,
      remark: ''
    })

    const checkHandle = row => {
      spareRecordDialogRef.value.showDialog = true
      spareRecordData.businessId = row.recordId
      spareRecordData.outStatus = row.outStatus
      spareRecordData.remark = row.remark
      spareRecordData.content = ''
    }

    const submitSpareRecord = async () => {
      const { data } = await getTabberData(dispatch, 'fetchGetCurNode', {
        businessId: spareRecordData.businessId
      })
      if (data) {
        data.content = spareRecordData.content
        const remark = spareRecordData.remark
        if (spareRecordData.outStatus == 0) {
          data.nodeName =
            spareRecordData.apprStatus == 1
              ? '【' + remark + '审核】通过'
              : '【' + remark + '审核】驳回'
        } else if (spareRecordData.outStatus == 1) {
          data.nodeName =
            spareRecordData.apprStatus == 1
              ? '【' + remark + '办结】通过'
              : '【' + remark + '办结】驳回'
        }
        let params = {
          curOrder: data,
          operateType: spareRecordData.operateType,
          apprStatus: spareRecordData.apprStatus
        }
        dispatch('fetchApprProcess', params).then(res => {
          if (res.code === 200) {
            commit('setError', {
              status: true,
              title: res.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            getSpareOutRecordTabberData()
            spareRecordDialogRef.value.showDialog = false
          }
        })
      }
    }
    // 修改
    const sparePartsRecordDialogRef = ref()
    const sparePartsFormRef = ref()

    const sparePartsRecordData = reactive({
      title: '修改',
      number: 0,
      why: '',
      partId: '',
      recordId: '',
      remark: ''
    })

    const editkHandle = row => {
      sparePartsRecordData.partId = row.partId
      sparePartsRecordData.recordId = row.recordId
      sparePartsRecordData.number = row.planOutStock
      sparePartsRecordData.why = ''
      sparePartsRecordData.remark = row.remark
      sparePartsRecordDialogRef.value.showDialog = true
    }

    const submitSparePartsRecord = () => {
      const { partId, recordId, number, why, remark } = sparePartsRecordData
      let nParams = {
        partId,
        recordId,
        planOutStock: number,
        outReason: why,
        remark
      }
      dispatch('fetchUpdataSpareOutRecord', nParams).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getSpareOutRecordTabberData() : (tabberData.page = 1)
          sparePartsRecordDialogRef.value.showDialog = false
        }
      })
    }

    const clickView = row => {
      router.push({ path: '/spareOutRecordAction', query: { recordId: row.recordId } })
    }

    onMounted(async () => {
       // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (getMemoryPage.value.searchData.companyId) {
        await getProjectData(getMemoryPage.value.searchData.companyId)
      }
      getSpareOutRecordTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      formetData,
      clickDelete,
      deleteSparePartsDialogRef,
      deleteSparePartsData,
      submitDeleteSpareParts,
      userId,
      spareRecordDialogRef,
      spareRecordData,
      checkHandle,
      submitSpareRecord,
      sparePartsRecordDialogRef,
      sparePartsFormRef,
      sparePartsRecordData,
      submitSparePartsRecord,
      editkHandle,
      clickView,
      unitOptions,
      projectData,
      selectUnit
    }
  }
}
</script>

<style lang="scss" scoped>
.spare-out-record {
  @extend %params-global;
}
</style>
